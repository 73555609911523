import getLink from './views/Api/request'

let links = []

getLink().then(res => {
  let result = JSON.parse(res.data)
  // console.log(result)
  if (result.code === 0)  {

    Object.assign(links, result.data)
  }
  // console.log(links)
})

const downloadFile = (params) => {
  const {
    platform = ''
  } = params

  if (links.length === 0) {
    return false
  }

  // console.log(platform)

  let res = links.find(item => {
    return item.platform === platform
  })

  if (res === null) {
    return false
  }

  window.open(res.updateLink)

  return true
} 

const getAppInfo = () => {
  let dataSet = {}
  // 先找az
  let res  = null
  res = links.find(item => {
    return item.platform === 'az'
  })

  dataSet['az'] = res
  // 再找ios
  res = links.find(item => {
    return item.platform === 'ios'
  })

  dataSet['ios'] = res

  // console.log(dataSet)

  if (!dataSet.az && !dataSet.ios) {
    return null
  } else {
    return dataSet
  }

}

export default {links, downloadFile, getAppInfo}
