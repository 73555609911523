import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/store.js'

Vue.config.productionTip = false



// import { Loading } from 'element-ui'
// Vue.use(Loading.directive)
// console.log(test)

Vue.directive('resize', {
  bind(el, binding) {
    let width = '', height = '';
    function get() {
      const style = document.defaultView.getComputedStyle(el);
      if (width !== style.width || height !== style.height) {
        binding.value({width, height});
      }
      width = style.width;
      height = style.height;
    }

    el.__vueReize__ = setInterval(get, 200);
  },
  unbind(el) {
    clearInterval(el.__vueReize__);
  },
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
