import { Axios } from 'axios'

const Base64 = require('js-base64').Base64

const service = new Axios({
  // baseURL: GET_VUE_APP_BASE_API(),
  timeout: 50000,
  withCredentials: true
})

const url = Base64.decode(setting.baseUrl)
// console.log(url)

const getLink = () => {

  const options = {
    url: url + `/web-api/install/v1/backend/${setting.appId}/install/location`,
    method: 'get'
  }
  // console.log(options)

  return new Promise((resolve, reject) => {
    service.get(options.url).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export default getLink
